/* .sensei_footer {
    background-color: #EFEFEF;
    padding: 0;
} */

.sensei_footer_pp {
    display: flex;
    padding: 0.2rem 3.12rem;
}

.sensei_footer_pp p {
    color: #4D5667;
    text-decoration: underline;
}

.sensei_footer_pp button {
    border: 0;
    padding-right: 0.69rem;
    cursor: pointer;
}

.footer-new{
    display: flex;
    gap: 2rem;
    padding: 1rem 3.12rem;
}

.footer-new a{
    /* color: #0043bf; */
    color: #090906;
    text-decoration: none;
}

.footer-new a:hover{
    text-decoration: underline;
}

@media screen and (max-width: 992px) {
    .footer-new{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 20px;
    }
    
    .footer-new a{
        font-size: 14px;
    }
}