body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'DM Sans'
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FAFAF9;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  /* background-color: #f4f4f9; */
}

.header {
  background-color: #4CAF50;
  color: white;
  text-align: center;
  padding: 1em 0;
}

.content {
  max-width: 800px;
  margin: 2em auto;
  padding: 1em;
  background: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

h1, h2 {
  color: #333;
}

ul {
  list-style-type: disc;
  padding-left: 1.5em;
}
