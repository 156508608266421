h1 {
    font-size: 1.5625rem;
}

.mobile_buttons {
    display: none;
}

.sensei_hero_img img {
    width: 28rem;
    height: 18rem;
}

.sensei_leftside h1 {
    color: #FFCD00;
    margin-bottom: 1rem;
}

.sensei_leftside p {
    color: #838383;
    width: 33rem;
    margin-top: 0;
    word-wrap: break-word;
    line-height: 2rem;
}

.sensei_rightside {
    display: flex;
    flex-direction: column;
    width: 50%;

}

.sensei_leftside {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 50%;
    min-height: 100vh;
    border-top-left-radius: 0.625rem;
    padding: 0 3rem;
}

.sensei_main {
    display: flex;
}

.sensei_rightside_center {
    text-align: center;
    margin: auto;
}

.sensei_rightside h1 {
    display: flex;
    height: 1.5rem;
    justify-content: center;
    color: #4D5667;
}

.sensei_rightside p {
    color: #838383;
}

.sensei_leftside_start {
    display: flex;
    flex-direction: column;
    margin: auto;
}

.btn1,
.btn2 {
    border-radius: 0.375rem;
    border: 1px solid #FFCD00;
    padding: 1rem 2.5rem;
    font-size: 1.125rem;
    width: 255px;
}

.btn1 {
    background: none;
    color: #FFCD00;
    margin-right: 1rem;
}

.btn2 {
    color: white;
    background: #FFCD00;
}

.indicators {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 100px;
}

.indicators span {
    display: inline-block;
    width: 3.0625rem;
    height: 0.375rem;
    border-radius: 0.3125rem;
    background-color: #efefef;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.indicators span.active {
    background-color: #FFCD00;
}

/* add media query for screen size 1148 below */
@media screen and (max-width: 1148px) {

    .btn1,
    .btn2 {
        border-radius: 0.375rem;
        border: 1px solid #FFCD00;
        padding: 0.9rem 2rem;
        font-size: 1rem;
        width: 220px;
    }

    .btn1 {
        margin-right: 0.5rem;
    }
    .sensei_leftside p {
        width: 29rem;
    }

    .footer{
        display: block;
    }
}

/* add media query for screen size 991 below */
@media screen and (max-width: 991px) {
    .sensei_leftside {
        display: none;
    }

    .sensei_rightside {
        width: 100%;
        margin: 20px 0px;
    }

    .mobile_buttons {
        display: inline;
        margin: 0 2rem;
    }
    .sensei_rightside_center {
        margin-top: 20px;
    }



}

/* add media query for screen size 512 below */

@media screen and (max-width: 512px) {
    .mobile_buttons {
        display: flex;
        flex-direction: column
    }
    .sensei_hero_img img {
        width: 20rem;
        height: 13rem;
    }

    .sensei_rightside h1 {
        font-size: 1.5rem;
    }

    .sensei_rightside p {
        font-size: 0.875rem;
    }

    .btn1,
    .btn2 {
        border-radius: 0.375rem;
        border: 1px solid #FFCD00;
        padding: 0.9rem 2rem;
        font-size: 0.9rem;
    }

    .btn1 {
        margin: 0;
        margin-bottom: 1rem;
    }

    .indicators {
        margin-bottom: 50px;
    }
}

/* add media query for screen size 330 below */
@media screen and (max-width: 330px) {
    .sensei_main {
        margin: auto;
    }
    .sensei_hero_img img {
        width: 18rem;
        height: 11rem;
    }

    .sensei_rightside h1 {
        font-size: 1.2rem;
    }

    .sensei_rightside p {
        font-size: 0.8rem;
    }

    .btn1,
    .btn2 {
        border-radius: 0.375rem;
        border: 1px solid #FFCD00;
        padding: 0.9rem 2rem;
        font-size: 1rem;
    }

    .btn1 {
        margin: 0;
        margin-bottom: 1rem;
    }

    .indicators {
        margin-bottom: 50px;
    }
}